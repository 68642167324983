<template>
<div class="footer">
    <div class="container">
        <div class="row">
            <div class="col-lg-4">
                <div class="logoImage d-flex flex-row">
                    <div>
                        <img src="/logoFooter.png" alt="" class="img-fluid" />
                    </div>
                    <div class="informacionEmpresa d-flex flex-column">
                        <div class="d-flex flex-column">
                            <div class="d-flex flex-row">
                                <i class="fa-solid fa-phone mr-1"></i>
                                <div>{{$t('home.p111')}}</div>
                            </div>
                            <div>+34 919 93 05 90</div>
                        </div>

                        <div class="d-flex flex-column" style="margin-top: 19px;">
                            <div class="d-flex flex-row">
                                <img src="/whatsapp.png" alt="what" class="mr-1" style="width:20px">
                                <div>WhatsApp</div>
                            </div>
                            <div>+34 642 43 52 57</div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4">
                <div class="segundoBloque d-flex flex-column">
                    <div>
                        <div class="titleStrong">{{$t('nav.p21')}}</div>
                    </div>
                    <div class="informacionEmpresa d-flex flex-column">
                        <div @click.prevent="CambiarRuta('Qrcheckin')">{{$t('nav.p7')}}</div>
                        <div @click.prevent="CambiarRuta('checkinOnlineRoute')">{{$t('nav.p9')}}</div>
                        <div @click.prevent="CambiarRuta('whatsappPage')">
                            {{$t('nav.p11')}}
                        </div>
                        <div @click.prevent="CambiarRuta('Upselling')">{{$t('nav.p13')}}</div>
                        <div @click.prevent="CambiarRuta('policePage')">{{$t('nav.p15')}}</div>
                        <div @click.prevent="CambiarRuta('MensajesAutomaticos')">
                            {{$t('nav.p22')}}
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4">
                <div class="segundoBloque d-flex flex-column">
                    <div>
                        <div class="titleStrong">{{$t('nav.p23')}}</div>
                    </div>
                    <div class="informacionEmpresa d-flex flex-column">
                        <router-link to="terminos" tag="li" class="d-flex flex-row mg0" style="cursor: pointer; color: #000">
                            <div>{{$t('nav.p24')}}</div>
                        </router-link>
                        <router-link :to="{ name: 'pHospedaje' }" tag="li" class="d-flex flex-row mg0" style="cursor: pointer; color: #000">
                            <div>{{$t('nav.p25')}}</div>
                        </router-link>
                        <router-link :to="{ name: 'pHuesped' }" tag="li" class="max2 d-flex flex-row" style="cursor: pointer; color: #000">
                            <div>{{$t('nav.p26')}}</div>
                        </router-link>
                    </div>

                    <div>
                        <div class="titleStrong mt-2">{{$t('nav.p30')}}</div>
                    </div>
                    <div class="informacionEmpresa d-flex flex-column">
                        <div><a :href="'https://api.whatsapp.com/send?phone=+34 642 43 52 57&text=' + $t('home.p114')" target="_blank" style="color:#000; text-decoration: none;">{{$t('nav.p27')}}</a></div>
                        <div>
                            <a style="text-decoration: none; color: #000" href="mailto:soporte@wacheckin.com" target="_blank">{{$t('nav.p28')}}</a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-12 mt-3">
                <span>© 2024 {{$t('nav.p29')}}</span>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    methods: {
        CambiarRuta(ruta) {
            if (ruta != this.$route.name) {
                this.$router.push({ name: ruta });
                window.scrollTo(0, 0); // Esto llevará la página al principio
            }
        },
    },
};
</script>

<style lang="scss">
.logoImage {
    align-items: flex-end;

    img {
        margin-right: 20px;
        width: 80px;
    }

    .informacionEmpresa {
        font-size: 15px;
        font-weight: 500;
    }
}

.segundoBloque {
    .informacionEmpresa {
        div {
            margin-bottom: 5px;
            cursor: pointer;
        }
    }
}

.titleStrong {
    color: #000;
    font-weight: bold;
    font-size: 19px;
}

.row {
    text-align: left;
}
</style>
