<template>
  <div>
    <!-- <div
      class="navInfo d-flex flex-row"
      style="background: #000;
        color: white;
        position: absolute;
        left: -0;
        right: 0;
        height: 55px 
        text-align: center !important;
        align-items: center;
    font-size: 15px;
        margin: auto !important;
        top: 0;
        justify-content: center !important;"
    >
      <div>
        {{ $t("home.p88") }}
        <img
          class=""
          style="width: 40px; padding-inline: 4px; margin-inline: 3px"
          src="/onetree.svg"
          alt=""
        />
      </div>
    </div> -->

    <div
      class="navInfo d-flex flex-row"
      style="background: #000;
        color: white;
        position: absolute;
        left: -0;
        right: 0;
        height: 55px;
        text-align: center !important;
        align-items: center;
        font-size: 15px;
        margin: auto !important;
        top: 0;
        justify-content: center !important;"
    >
      <div>
        {{$t('home.p113')}}
      </div>
      <div class="ml-4" style="text-decoration: underline; font-weight: 600">
        <a href="https://sede.mir.gob.es/opencms/export/sites/default/es/procedimientos-y-servicios/hospedajes-y-alquiler-de-vehiculos/" target="_blank" style="text-decoration: none; color: white;">
          Más información <i class="far fa-chevron-right ml1"></i>
        </a>
      </div>
    </div>

    <div
      ref="nave"
      class="Nav d-flex"
      :style="menuActive == 1 ? { 'border-bottom': '1px solid #dbdbdb' } : {}"
    >
      <div
        class="menu-nav d-flex flex-row"
        style="justify-content: space-between; width: 100%"
      >
        <ul class="d-flex flex-row" style="padding-left: 5px">
          <li style="margin-left: 0">
            <div class="logo">
              <a href="/">
                <img
                  src="/logo.png"
                  class="img-fluid"
                  alt=""
                  v-animate-css="'slideInDown'"
                />
              </a>
            </div>
          </li>

          <li
            :style="menuActive == 1 ? { color: 'green' } : {}"
            style="padding-top: 9px !important"
            @click="ChangeStateMenu()"
          >
            {{ $t("nav.p1") }}
            <i
              class="fa-regular fa-chevron-down ml-2"
              :style="
                menuActive == 1
                  ? { transition: '0.4s', transform: 'rotate(180deg)' }
                  : { transition: '0.4s', transform: 'rotate(0deg)' }
              "
            ></i>
          </li>
          <li
            @click.prevent="CambiarRuta('Integrations')"
            style="padding-top: 9px !important"
          >
            {{ $t("nav.p2") }}
          </li>
          <li style="padding-top: 9px !important" @click.prevent="CambiarRuta('Price')">
            {{ $t("nav.p3") }}
          </li>
          <!-- <li
            @click.prevent="abrirModal()"
            style="
              padding-top: 9px !important;
              background: #ffffff;
              padding-top: 0 !important;
              margin-top: 10px;
              border-radius: 10px;
              padding-left: 10px;
              padding-right: 10px;
              color: green;
              padding-top: 3px !important;
              padding-bottom: 3px !important;
            "
          >
            {{ $t("nav.p4") }}
          </li> -->
        </ul>

        <ul class="d-flex flex-row">
          <!-- Lugar del icono de idiomas   -->
          <!-- icono del idioma   -->
          <div class="icon-idioma" style="margin-top: 4px">
            <img
              v-if="idioma == 'es'"
              @click.prevent="activarModalIdiomas()"
              src="/espana.png"
              style="width: 22px; cursor: pointer"
              alt=""
            />
            <img
              v-if="idioma == 'co'"
              @click.prevent="activarModalIdiomas()"
              src="/colombia.png"
              style="width: 22px; cursor: pointer"
              alt=""
            />
            <img
              v-if="idioma == 'pt'"
              @click.prevent="activarModalIdiomas()"
              src="/portugal.png"
              style="width: 24px; cursor: pointer"
              alt=""
            />
            <img
              v-if="idioma == 'en'"
              @click.prevent="activarModalIdiomas()"
              src="/uuee.png"
              style="width: 22px; cursor: pointer"
              alt=""
            />
            <img
              v-if="idioma == 've'"
              @click.prevent="activarModalIdiomas()"
              src="/venezuela.png"
              style="width: 22px; cursor: pointer"
              alt=""
            />

            <!-- <i
            class="icon-leng fas fa-globe"
            @click.prevent="activarModalIdiomas()"
            style="font-size: 20px; margin-right: 41px; cursor: pointer"
          ></i> -->
          </div>

          <div class="hloqueSeparador"></div>

          <li style="padding-top: 9px !important">
            <a href="https://app.wacheckin.com/register">
              <button
                class="btn register"
                :style="{ background: '#fff' }"
                style="
                  border-radius: 9px !important;
                  font-size: 15px;
                  font-weight: 700;
                  color: rgb(17, 177, 77);
                  background: rgb(255, 255, 255);
                  padding: 9px 18px;
                "
              >
                {{ $t("nav.p5") }}
              </button>
            </a>
          </li>

          <li style="padding-top: 9px !important; margin-left: 14px">
            <a href="https://app.wacheckin.com" style="">
              <button
                class="btn register"
                style="
                  border-radius: 9px !important;
                  font-size: 15px;
                  font-weight: 700;
                  color: rgb(0, 0, 0);
                  background: rgb(0 0 0 / 5%);
                  padding: 9px 18px;
                "
              >
                {{ $t("nav.p6") }}
              </button></a
            >
          </li>
        </ul>
      </div>

      <div
        class="desplegable lg"
        v-if="menuActive == 1"
        v-animate-css="'slideInDown'"
        style="
          position: absolute;
          top: 76px;
          background: white;
          left: 0;
          right: 0;
          min-height: 200px;
          padding: 20px 90px;
          z-index: 1;
          box-shadow: 0px 6px 43px 46px #0000000d;
        "
      >
        <div class="menus d-flex flex-row">
          <div
            class="bloqueItems one d-flex flex-row"
            @click.prevent="CambiarRuta('Qrcheckin')"
          >
            <div class="icono">
              <i class="fa-solid fa-qrcode" style="color: green"></i>
            </div>
            <div class="contenido d-flex flex-column">
              <div class="titleItems mb-1">{{ $t("nav.p7") }}</div>
              <div class="desItem">{{ $t("nav.p8") }}</div>
            </div>
          </div>

          <div
            class="bloqueItems dos d-flex flex-row"
            @click.prevent="CambiarRuta('checkinOnlineRoute')"
          >
            <div class="icono">
              <i class="fa-solid fa-scanner-touchscreen" style="color: #2196f3"></i>
            </div>
            <div class="contenido d-flex flex-column">
              <div class="titleItems mb-1">{{ $t("nav.p9") }}</div>
              <div class="desItem">
                {{ $t("nav.p10") }}
              </div>
            </div>
          </div>

          <div
            class="bloqueItems tres d-flex flex-row"
            @click.prevent="CambiarRuta('whatsappPage')"
          >
            <div class="icono">
              <i class="fa-brands fa-whatsapp" style="color: #4caf50"></i>
            </div>
            <div class="contenido d-flex flex-column">
              <div class="titleItems mb-1">{{ $t("nav.p11") }}</div>
              <div class="desItem">
                {{ $t("nav.p12") }}
              </div>
            </div>
          </div>

          <div
            class="bloqueItems cuatro d-flex flex-row"
            @click.prevent="CambiarRuta('Upselling')"
          >
            <div class="icono">
              <i class="fa-solid fa-euro-sign" style="color: #7986cb"></i>
              <!-- <i class="fa-solid fa-file-contract" style="color: #7986cb"></i> -->
            </div>
            <div class="contenido d-flex flex-column">
              <div class="titleItems mb-1">{{ $t("nav.p13") }}</div>
              <div class="desItem">
                {{ $t("nav.p14") }}
              </div>
            </div>
          </div>

          <div
            class="bloqueItems cinco d-flex flex-row"
            @click.prevent="CambiarRuta('policePage')"
          >
            <div class="icono">
              <i class="fa-duotone fa-user-police"></i>
            </div>
            <div class="contenido d-flex flex-column">
              <div class="titleItems mb-1">{{ $t("nav.p15") }}</div>
              <div class="desItem">
                {{ $t("nav.p16") }}
              </div>
            </div>
          </div>

          <div
            class="bloqueItems seis d-flex flex-row"
            @click.prevent="CambiarRuta('MensajesAutomaticos')"
          >
            <div class="icono">
              <!-- <i class="fa-solid fa-euro-sign" style="color: #ff9800"></i> -->
              <i class="fa-duotone fa-message-lines" style="color: #ff9800"></i>
            </div>
            <div class="contenido d-flex flex-column">
              <div class="titleItems mb-1">{{ $t("nav.p17") }}</div>
              <div class="desItem">
                {{ $t("nav.p18") }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- <modalVideo /> -->
      <!-- <ModalLenguaje /> -->
    </div>
  </div>
</template>
<script>
// import ModalLenguaje from "./modalLenguaje.vue";
// import modalVideo from "./modalVideo.vue";
export default {
  // components: { ModalLenguaje },
  name: "Nav",
  mounted() {
    this.idioma = localStorage.getItem("appLanguage") || "es";
  },
  data() {
    return {
      menuActive: 2,
      idioma: "",
    };
  },
  methods: {
    activarModalIdiomas() {
      console.log("Hace click");
      $("#modalLenguaje").modal("show");
    },

    abrirModal() {
      $("#modalVideo").modal("show");
      var video = document.getElementById("mi-video");
      video.play();
    },
    CambiarRuta(ruta) {
      if (ruta != this.$route.name) {
        this.$router.push({ name: ruta });
      }
    },
    ChangeStateMenu() {
      if (this.menuActive == 1) {
        this.menuActive = 2;
      } else {
        this.menuActive = 1;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.bloqueItems {
  width: 200px;
  text-align: left;
  transition: 0.5s;
  padding: 12px;
  cursor: pointer;
  margin-right: 20px;

  .contenido {
    margin-left: 15px;
  }

  .titleItems {
    font-weight: 800;
    font-size: 14px;
    text-transform: uppercase;
  }
  .desItem {
    font-size: 12px;
  }
}

// HOVERS

.one {
  &:hover {
    background: #e8f5e9;
    border-radius: 10px;
  }
}

.dos {
  &:hover {
    background: #e3f2fd;
    border-radius: 10px;
  }
}

.tres {
  &:hover {
    background: #e0f2f1;
    border-radius: 10px;
  }
}

.cuatro {
  &:hover {
    background: #e8eaf6;
    border-radius: 10px;
  }
}

.cinco {
  &:hover {
    background: #f5f5f5;
    border-radius: 10px;
  }
}

.seis {
  &:hover {
    background: #fff3e0;
    border-radius: 10px;
  }
}

li {
  button {
    border-radius: 8px !important;
    font-weight: 700;
    color: white;
    // box-shadow: 0px 1px 6px 2px #eee;
  }
}
</style>

<style lang="scss" scoped>
.Nav {
  height: 76px;
  background: #fff;
  padding: 0px 65px;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  left: 0;
  right: 0;
  z-index: 1000;
  top: 54px;

  .dropdown-menu .dropdown-item {
    padding: 15px !important;
  }

  .dropdown-item:focus,
  .dropdown-item:hover {
    border-radius: 16px !important;
  }

  .dropdown-menu .dropdown-item {
    font-weight: 600 !important;
  }

  .menu-nav {
    ul {
      height: 100%;
      align-items: center;
      margin-bottom: 0;
      li {
        color: #0d1f00;
        -webkit-font-smoothing: antialiased;
        font-weight: 700;
        line-height: 26px;
        margin: 0px 0px;
        transition: opacity 0.2s ease-in;
        margin-left: 34px;
        cursor: pointer;
        font-size: 15px;
        transition: 0.4s;

        &:hover {
          color: green;
        }
      }
    }
  }
  .logo {
    img {
      width: 157px;
    }
  }
}
</style>
